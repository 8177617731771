import { Dialog } from "../../components/Dialog";
import { t } from "../../i18n";
import "./ModalImageSelection.scss";
import "./ImageSelectionPreview.scss";
import { AppState } from "../../types";
import ImageSelectionPreview from "./ImageSelectionPreview";
import { ExcalidrawImperativeAPI, PreviewImage } from "../../types";
import { useState, useEffect } from "react";
import { getRoomIdFromUrl } from "../../utils";
const ModalImageSelection = ({
  handleClose,
  theme,
  onImageSelected,
  excalidrawAPI,
}: {
  handleClose: (image_selected: string) => void;
  theme: AppState["theme"];
  onImageSelected: (image_url_selected: string) => void;
  excalidrawAPI: ExcalidrawImperativeAPI;
}) => {
  const [imageList, setImageList] = useState({
    loading: true,
    previewImages: [] as PreviewImage[],
  });

  const getImageList = () => {
    const roomId = getRoomIdFromUrl();
    if (!roomId) {
      setImageList({ loading: false, previewImages: [] as PreviewImage[] });
    }
    const api_url = `${process.env.REACT_APP_BIOSFERA_WHITEBOARD_API_URL}/image/${roomId}`;
    fetch(api_url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Cannot load image list. Error: ${response.status}`);
        }
        return response.json();
      })
      .then((jResponse) => {
        const result = jResponse.images as PreviewImage[];
        setImageList({ loading: false, previewImages: result });
      })
      .catch((e) => {
        //pass
      });
    setImageList({ loading: false, previewImages: [] as PreviewImage[] });
  };

  const internalOnImageSelected = (image_url_selected: string) => {
    if (onImageSelected) {
      onImageSelected(image_url_selected);
    }
    handleClose(image_url_selected);
  };
  const renderModalImageSelectionDialogDialog = () => {
    return (
      <div className="RoomDialog-modal">
        <div className="img-selector-container">
          {imageList.previewImages.map((listitem) => (
            <ImageSelectionPreview
              preview_image={listitem}
              key={listitem.id}
              excalidrawAPI={excalidrawAPI}
              onImageSelected={internalOnImageSelected}
            />
          ))}
        </div>
      </div>
    );
  };

  const internal_dialog_close_request = () => {
    handleClose("");
    onImageSelected("");
  };

  const loadPreviewModal = () => {
    if (imageList.loading) {
      return (
        <Dialog
          className="My_Modal"
          small
          onCloseRequest={internal_dialog_close_request}
          title={t("imageSelection.title")}
          theme={theme}
        >
          <div className="RoomDialog-modal-flex">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </Dialog>
      );
    } else if (imageList.previewImages.length === 0) {
      return (
        <Dialog
          className="My_Modal"
          small
          onCloseRequest={internal_dialog_close_request}
          title={t("imageSelection.title")}
          theme={theme}
        >
          <span className="RoomDialog-modal-flex text-notfound">
            <strong>{t("imageSelection.noImageFound")}</strong>
          </span>
        </Dialog>
      );
    }
    return (
      <Dialog
        className="My_Modal"
        small
        onCloseRequest={internal_dialog_close_request}
        title={t("imageSelection.title")}
        theme={theme}
      >
        {renderModalImageSelectionDialogDialog()}
      </Dialog>
    );
  };

  useEffect(() => {
    if (imageList.loading) {
      getImageList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{loadPreviewModal()}</>;
};

export default ModalImageSelection;

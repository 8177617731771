import { useState } from "react";
import { ExcalidrawImperativeAPI, PreviewImage } from "../../types";
import "./ImageSelectionPreview.scss";
import { useEffect } from "react";
import { getRoomIdFromUrl } from "../../utils";

const ImageSelectionPreview = (props: {
  preview_image: PreviewImage;
  excalidrawAPI: ExcalidrawImperativeAPI;
  onImageSelected: (image_url_selected: string) => void;
}) => {
  const [image, setImage] = useState({ loaded: false, image: "", url: "" });

  const onInternalImageSelected = () => {
    return (event: React.MouseEvent) => {
      event.preventDefault();
      if (!props.onImageSelected) {
        return;
      }
      props.onImageSelected(props.preview_image.url);
    };
  };

  useEffect(() => {
    if (props.preview_image.url) {
      const roomId = getRoomIdFromUrl();
      if (!roomId) {
        return;
      }
      fetch(props.preview_image.url)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Cannot load image. Error: ${response.status}`);
          }
          return response.blob();
        })
        .then((blob) => {
          setImage({
            loaded: true,
            image: URL.createObjectURL(blob),
            url: props.preview_image.url,
          });
        })
        .catch((e) => {
          setImage({
            loaded: false,
            image: "https://query.dental/c/image-not-found.jpg",
            url: props.preview_image.url,
          });
        });
    }
  }, [props.preview_image.url]);

  const renderImage = () => {
    const className = `img-selector ${image.loaded ? "found" : "notfound"}`;
    if (image.url === props.preview_image.url) {
      return (
        <div className="img-preview-item">
          <div>
            <img
              className={className}
              src={image.image}
              alt={props.preview_image.description}
              onClick={onInternalImageSelected()}
            />
          </div>
          <div>
            <span>
              {props.preview_image.description.length > 25
                ? `${props.preview_image.description.substring(0, 25)}...`
                : props.preview_image.description}
            </span>
          </div>
        </div>
      );
    }
    return (
      <img
        className="img-selector is-loading"
        onClick={onInternalImageSelected()}
        alt=""
      />
    );
  };

  return <>{renderImage()}</>;
};

export default ImageSelectionPreview;

import { PureComponent } from "react";
import { ExcalidrawImperativeAPI } from "../../types";
import ModalImageSelection from "./ModalImageSelection";
import { atom, useAtom } from "jotai";
import { ENV } from "../../constants";
import { jotaiStore } from "../../jotai";
export const modalImageSelectionDialogShownAtom = atom(false);

interface ImageSelectionState {
  errorMessage: string;
  activeRoomLink: string;
}

interface PublicProps {
  excalidrawAPI: ExcalidrawImperativeAPI;
  onImageSelected: (image_url_selected: string) => void;
}

type Props = PublicProps & { modalIsShown: boolean };

class ImageSelection extends PureComponent<Props, ImageSelectionState> {
  excalidrawAPI: Props["excalidrawAPI"];
  onImageSelected: (image_url_selected: string) => void;
  activeIntervalId: number | null;
  idleTimeoutId: number | null;

  constructor(props: Props) {
    super(props);
    this.state = {
      errorMessage: "",
      activeRoomLink: "",
    };
    this.excalidrawAPI = props.excalidrawAPI;
    this.activeIntervalId = null;
    this.idleTimeoutId = null;
    this.onImageSelected = props.onImageSelected;
  }

  componentDidMount() {
    if (
      process.env.NODE_ENV === ENV.TEST ||
      process.env.NODE_ENV === ENV.DEVELOPMENT
    ) {
      window.image_selection =
        window.image_selection || ({} as Window["image_selection"]);
      Object.defineProperties(window, {
        image_selection: {
          configurable: true,
          value: this,
        },
      });
    }
  }

  componentWillUnmount() {
    if (this.activeIntervalId) {
      window.clearInterval(this.activeIntervalId);
      this.activeIntervalId = null;
    }
    if (this.idleTimeoutId) {
      window.clearTimeout(this.idleTimeoutId);
      this.idleTimeoutId = null;
    }
  }

  handleClose = (image_selected: string) => {
    jotaiStore.set(modalImageSelectionDialogShownAtom, false);
  };

  render() {
    const { modalIsShown } = this.props;

    return (
      <>
        {modalIsShown && (
          <ModalImageSelection
            handleClose={this.handleClose}
            theme={this.excalidrawAPI.getAppState().theme}
            onImageSelected={this.onImageSelected}
            excalidrawAPI={this.excalidrawAPI}
          />
        )}
      </>
    );
  }
}

declare global {
  interface Window {
    image_selection: InstanceType<typeof ImageSelection>;
  }
}

if (
  process.env.NODE_ENV === ENV.TEST ||
  process.env.NODE_ENV === ENV.DEVELOPMENT
) {
  window.image_selection =
    window.image_selection || ({} as Window["image_selection"]);
}

const _ImageSelection: React.FC<PublicProps> = (props) => {
  const [modalImageSelectionDialogShown] = useAtom(
    modalImageSelectionDialogShownAtom,
  );
  return (
    <ImageSelection {...props} modalIsShown={modalImageSelectionDialogShown} />
  );
};

export default _ImageSelection;

export type TCollabClass = ImageSelection;
